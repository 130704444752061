import { useStorage } from "vue3-storage";

export default function guard({ next }) {
    const storage = useStorage();
    
    const numOfHours = 10;
    const date = new Date();
    const timestampInMs = date.getTime();
    // get current date time in UNIX
    const unixTimestamp = Math.floor(timestampInMs / 1000);
    // get expire date from google token
    let google_exp_date = storage.getStorageSync("exp_date");

    google_exp_date = parseInt(google_exp_date) + (numOfHours * 60 * 60);

    var expdate = new Date(google_exp_date * 1000);

    var hours = expdate.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + expdate.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + expdate.getSeconds();
    var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    console.log(formattedTime);

    if (unixTimestamp < google_exp_date) {
        return next();
    } else {
        storage.removeStorageSync("exp_date");
        storage.removeStorageSync("is_logged");
        storage.removeStorageSync("request_user");
        storage.removeStorageSync("user_token");
        storage.removeStorageSync("user");
        storage.clearStorageSync();
        next({ name: 'loginScreen' })
        return;
    }
}
