<template>
  <div style="position: relative;">
    <div class="circle-gradient ">
    </div>
      <span class="wait-label" :style="{color: textColor}">Please wait...</span>
  </div>
</template>

<script>

export default {
 name: 'GradientSpinner',
 props: {
  textColor: {
    type: String,
    default: 'white',
  }
 }
}
</script>


<style scoped>
.circle-gradient {
  background-image: linear-gradient(90deg, #1d4370, #04bbf1 ,#94c12e);
  height: 200px;
  width: 200px;
  /* cut hole in center */
  -webkit-mask: radial-gradient(93px, #0000 99%, #000);
          mask: radial-gradient(93px, #0000 99%, #000);
  border-radius: 50%;
  /* only display top quarter of the circle" */
  clip-path: polygon(0 0, -50% 50%, 0 100%, 50% 150%, 100% 100%, 150% 50%, 100% 0, 50% 50%);
  animation: spin-clip 1s linear infinite;
}

@keyframes spin-clip {
  0% { clip-path: polygon(-25% -25%, -25% 50%, -25% 125%, 50% 125%, 125% 125%, 125% 50%, 125% -25%, 50% 50%); }
    12.5% { clip-path: polygon(50% -50%, -25% -25%, -50% 50%, -25% 125%, 50% 150%, 125% 125%, 150% 50%, 50% 50%); }
  25% { clip-path: polygon(125% -25%, 50% -50%, -25% -25%, -50% 50%, -25% 125%, 50% 150%, 125% 125%, 50% 50%); }
    37.5% { clip-path: polygon(150% 50%, 125% -25%, 50% -50%, -25% -25%, -50% 50%, -25% 125%, 50% 150%, 50% 50%); }
  50% { clip-path: polygon(125% 125%, 150% 50%, 125% -25%, 50% -50%, -25% -25%, -50% 50%, -25% 125%, 50% 50%); }
    62.5% { clip-path: polygon(50% 150%, 125% 125%, 150% 50%, 125% -25%, 50% -50%, -25% -25%, -50% 50%, 50% 50%); }
  75% { clip-path: polygon(-25% 125%, 50% 150%, 125% 125%, 150% 50%, 125% -25%, 50% -50%, -25% -25%, 50% 50%); }
    87.5% { clip-path: polygon(-50% 50%, -25% 125%, 50% 150%, 125% 125%, 150% 50%, 125% -25%, 50% -50%, 50% 50%); }
  100% { clip-path: polygon(-25% -25%, -25% 50%, -25% 125%, 50% 125%, 125% 125%, 125% 50%, 125% -25%, 50% 50%); }
}

.wait-label {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 50px);
}
</style>