import { createRouter, createWebHistory } from "vue-router";
import LoginScreen from "@/components/login/LoginScreen.vue";
import HomeScreen from "@/components/home/HomeScreen.vue";
import PumpingCalculator from "@/components/pumpingCalculator/PumpingCalculator.vue";
import AboutScreen from "@/components/about/AboutScreen.vue";
import guard from '../utils/guard';

// Vue.use(Router);
// const router = new Router({

const routes = [
  {
    path: "/",
    redirect: '/login',
  },
  {
    path: "/login",
    name: "loginScreen",
    component: LoginScreen,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: "/Home",
    alias: "/HomeScreen",
    name: "HomeScreen",
    props: true,
    component: HomeScreen,
    meta: {
      middleware: guard,
    },
  },
  {
    path: "/PumpingCalculator",
    alias: "/PumpingCalculator",
    name: "PumpingCalculator",
    props: true,
    component: PumpingCalculator,
    meta: {
      middleware: guard,
    },
  },
  {
    path: "/about",
    name: "About",
    component: AboutScreen,
    meta: {
      middleware: guard,
    },
  },

];
// router.beforeEach((to, from, next) => {
//   const lang = to.params.lang
//   loadLanguageAsync(lang).then(() => next())
// })


// Creates a `nextMiddleware()` function which not only  runs the default `next()` callback but also triggers , the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,   the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(parameters);
    // Then run the subsequent Middleware with a new   `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index, 1);
    subsequentMiddleware({ context, next: nextMiddleware });
  };
}

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ context, next: nextMiddleware });
  }

  return next();
});

export default router
