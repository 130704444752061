<template>
  <div class="info-panel" @click="copyToClipBoard()">
    You can contact us by sending an email at: <br>
    <span style="color: blue; cursor: pointer;">
      hdcs-apps.support@holcim.com
      <font-awesome-icon :icon="['far', 'copy']" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'ContactInfoPanel',
  methods: {
    copyToClipBoard() {
      navigator.clipboard.writeText("hdcs-apps.support@holcim.com");
      this.$toast.success('Copied to clipboard!', { position: "top", duration: 2000 });
    }
  }
}
</script>

<style scoped>
  .info-panel {
    position: absolute;
    z-index: 1;
    top: -15px;
    left: 286px;
    /*
    min-height: 100px;
    */
    min-width: 300px;
    width: fit-content;
    height: fit-content;
    border: 1px solid grey;
    border-radius: 2px;
    background-color: white;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.12);
    padding: 8px;
  }
 </style>