<template>
  <div style="border:15px solid white">
    <!--
      
      <h2>Welcome to iCONCrete!<br></h2>
      <br>
      <img src="../../assets/HOLCIM_Logo_iCONCrete_sRGB_Smartflow.png" width="510" align="middle"/>
      <br><br><br>
      <img src="../../assets/HOLCIM_Logo_iCONCrete_sRGB_Smartcast.png" width="500" align="middle"/>
      <br><br><br>
      <img src="../../assets/HOLCIM_Logo_iCONCrete_sRGB_Thermal.png" width="470" align="middle"/>
    -->
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: 'HomeScreen'
});
</script>

<style>
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
</style>
