<template>
	<div>
		<input type="file" id="concrete-file-input" name="concrete-file-input"
			accept=".txt,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			@change="$emit('fileChanged', $event)" style="display: none">
		<div class="input-group">
			<label type="text" for="concrete-file-input" class="form-control" style="background-color: white!important; cursor: pointer;" readonly 
				placeholder="No file selected">{{ fileName || 'No file selected' }}</label>
			<div class="input-group-append">
				<label class="btn btn-primary" for="concrete-file-input" style="border-radius: 0 2px 2px 0;">Browse</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileInput',
	props: {
		fileName: String,
	},
	emits: [
		'fileChanged',
	],
	data() {
		return {};
	},
}
</script>