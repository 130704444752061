// used to get unsafe area trace. It needs to identify 4 key points of pump trace:
// - 1: highest point (0; maxY)
// - 2: start of declining Y (x = value at last maxY; y = maxY)
// - 3: end of declining Y (x = maxX; y = last non-0)
// - 4: last point (x = maxX; y = 0)
// we then draw a shape for the area. 4 first points are 1, 2, 3, 4, 
// the 4 next are:
// (x4 * 0.9; y4 * 0.8)  =>  (maxX * 0.9; 0)
// (x3 * 0.9; y3 * 0.8) 
// (x2 * 0.9; y2 * 0.8)
// (x1 * 0.9; y1 * 0.8)  =>  (0; maxY * 0.8)
export function getMarginArea(plot, Xmargin = 0.1, Ymargin = 0.2) {
  let area_plot = {x: [], y: []};

  let pump_plot_length = plot.y.length;
  let maxX = Math.max(...plot.x);
  let maxY = Math.max(...plot.y);
  let last_maxY_index = -1;
  plot.y.forEach((item) => {
    if (item < maxY) {
      return;
    }
    last_maxY_index += 1;
  });

  // add 4 points to curves, x then y
  area_plot.x = plot.x
    .concat([
      maxX * (1.0 - Xmargin),  // percentage of max X (will be associated with zero Y)
      maxX * (1.0 - Xmargin),  // percentage of max X (will be associated with non-zero Y)
      //...bridgeX,
      plot.x[last_maxY_index] * (1.0 - Xmargin), // percentage of X value when Y value was at max
      0, // back to origin
      // -5
    ])
  area_plot.y = plot.y
    .concat([
      0,  // starting at 0
      plot.y[pump_plot_length - 2] * (1.0 - Ymargin),  // up to percentage of Y value just before the plummeting of curve
      // TODO add point here
      maxY * (1.0 - Ymargin),  // percentage of max Y value
      maxY * (1.0 - Ymargin)   // percentage of max Y value
    ])
  return area_plot
};

export function hasSpecialCharacters(content) {
  var matches = content.match(/[#:;*\\?!${}()[\]@~%=<>`"]/g);
  return !!matches;
};
