<template>
  <div>

    <div v-if="index > 0" class="row g-0 reducer" >
      <!-- optionnal reducer -->
        <b>Connecter length (m):</b>
        <input v-model.number="sectionData.reducerLength" type="number" class="form-control" min="0"
          @change="changeValue()">
      <!-- end optionnal reducer -->
    </div>

    <div class="section-border">
      <div class="row g-0 ">
        <div class="d-flex section-head justify-content-between align-items-center" @click="requestCollapse()" style="min-height:52px;">
          <button :id="'collapse-section-btn-'+ index"   type="button" class="button-no-decoration" data-bs-toggle="collapse"
            :data-bs-target="'#collapse-section-' + index">
            <span class="section-head-text">Section {{ index + 1 }}</span>
          </button>

          <!--  Delete component button -->
          <div v-if="index !== 0" class="d-flex justify-content-center align-items-center" @click="requestDelete()" >
            <button type="button" class="btn btn-danger btn-sm" :disabled="index == 0">
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
          </div>
          <!-- End Delete button -->
        </div>
      </div>

      <!-- Collapsible Wrapper -->
      <div :id="'collapse-section-' + index" class="collapse section-body" style="position: relative;">

        <div class="row" style="margin-top: 24px;">
          <div class="col me-1">
            <span>Pipe diameter (mm):</span>
            <input v-model.number="sectionData.pipeDiameter" type="number"
              class="form-control" min="1" max="300" @change="changeValue()">
          </div>
          <div class="col mb-1">
            <span>Pipe length (m):</span>
            <input v-model.number="sectionData.pipeLength" type="number" class="form-control"
            min="1" @change="changeValue()">
          </div>
        </div>


        <div v-if="isListOpen" class="elbow-list">
          <button class="elbow-option btn btn-outline-primary" v-for="item, index in elbowTypes" :key="index" type="button"
            @click="addElbow(item)">
            {{ item.angle }}°
            <br>
            {{ item.diameter }}mm
          </button>
        </div>

        <div class="d-flex" style="margin-top:18px;padding-bottom: 40px;">
          <button type="button" class="btn btn-outline-primary open-elbow-list-button" @click="openElbowList($event)">Add
          elbow</button>
          <div class="elbow" v-for="item, index of sectionData.elbows" :key="index">
            <div class="elbow-top" style="padding: 8px;">
              <img width=90 height=90 :src="require(`@/assets/elbow_${item.type}.png`)" alt="">
            </div>
            <div class="elbow-bottom">
              <font-awesome-icon class="align-self-center" icon="fa-solid fa-xmark"
                style="color:red;padding:0px;width:16px;display:inline-block;font-size: 24px;cursor: pointer;" @click="removeElbowAt(index)" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'PipelineSection',
  props: {
    index: null,
    initialData: Object,
  },
  emits: [
    'sectionChange',
    'requestDelete'
  ],
  mounted() {
    this.sectionData = this.initialData;
  },
  data() {
    return {
      isListOpen: false,
      sectionData: {},
      elbowTypes: [
        { type: '90_250', diameter: 250, angle: 90 },
        { type: '90_1000', diameter: 1000, angle: 90 },
        { type: '45', diameter: 1000, angle: 45 },
        { type: '30', diameter: 1000, angle: 30 },
      ]
    }
  },
  methods: {
    openElbowList(event) {
      event.stopPropagation();
      this.isListOpen = true;
    },

    closeElbowList() {
      this.isListOpen = false;
    },

    addElbow(elbow) {
      this.sectionData.elbows.push(elbow);
      this.sectionData[`nbElbows${elbow.type}`] += 1;
      this.closeElbowList();
      this.$emit('sectionChange', this.index, this.sectionData);
    },

    removeElbowAt(index) {
      let elbowType = this.sectionData.elbows.splice(index, 1)[0].type;
      this.sectionData[`nbElbows${elbowType}`] -= 1;
      this.$emit('sectionChange', this.index, this.sectionData);
    },

    async changeValue() {
      if (isNaN(parseFloat(this.sectionData.pipeDiameter))) {
        this.sectionData.pipeDiameter = 1;
      }
      if (this.sectionData.pipeDiameter < 1) {
        this.sectionData.pipeDiameter = 1;
      }
      if (isNaN(parseFloat(this.sectionData.pipeLength))) {
        this.sectionData.pipeLength = 1;
      }
      if (this.sectionData.pipeLength < 1) {
        this.sectionData.pipeLength = 1;
      }
      this.$emit('sectionChange', this.index, this.sectionData);
    },

    requestDelete() {
      if (this.index === 0) {
        this.$toast.error("Pipeline must have at least one section", { position: "top", duration: 2000 });
        return;
      }
      this.$emit('requestDelete', this.index);
    },
    requestCollapse() {
      const collapseButton = document.getElementById('collapse-section-btn-' + this.index);
      collapseButton.click();
    }
  },
}
</script>

<style scoped>
.reducer {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  background: #F5F6FA;
  padding: 30px 12px;
  margin-bottom: 24px;
}

.section-head {
  color: #1A4370;
  border: none;
  background-color: white;
  text-align: left;
  padding: 12px 12px 12px 21px !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.section-head-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.section-border {
  border-radius: 3px;
  border: 1px solid #BFB9B4;
}

.section-body {
  border-top: solid 1px #BFB9B4 !important;
  padding: 0 30px;
}

.open-elbow-list-button {
  width: 92px!important;
  height: 132px!important;
  border-radius: 2px;
  border: 1px dashed #1A4370!important;
}

.elbow-option{
  border-radius: 2px;
  border: 1px dashed #1A4370!important;
}


.elbow-top {
  border-radius: 4px 4px 0 0;
  border: solid 1px #BFB9B4 !important;
  border-bottom: none !important;
}

.elbow-bottom {
  border: solid 1px #BFB9B4 !important;
  border-top: none !important;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
}


.pipesection {
  text-align: center;
  font-size: 24px;
  border: 2px solid white;
  background-color: rgb(29, 67, 112);
}

.pipesection b {
  color: white;
}

.pipeproperty {
  text-align: center;
  font-size: 20px;
}

.pipesubproperty {
  text-align: center;
  font-size: 14px;
}

input {
  margin: 1px;
}

.elbow-list {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 250px;
  left: 26px;
  z-index: 10;
}

.elbow-option {
  width: 80px;
  background-color: white;
  color: #1A4370;
}

.elbow-option:hover {
  background-color: #1A4370;
  color: white;
}

.elbows-container {
  display: flex;
}

.elbow {
  display: flex;
  flex-direction: column;
  margin-left: 6px;

}
</style>