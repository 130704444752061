<template>
  
  <div class="form-control menu-list-button align-items-begin" @click="openMenuList($event);">
    <span>{{ displayedListLabel }}</span>
    <div v-if="isMenuListOpen" class="menu-list d-flex " @mouseleave="setHoveredMenuIndex(null)">

      <div class="menu-item" v-for="(menu, index) of menus" :key="index" @mouseenter="setHoveredMenuIndex(index);">
        <div class="d-flex justify-content-between">
          <span style="margin-left:10px;">{{ menu.label }}</span>
          <span style="margin-right:8px;">{{ menu.children.length > 0 ? ' >' : ''}}</span>
        </div>
        <div v-if="hoveredMenuIndex === index" class="submenu-list" style="position: absolute;z-index: 11" 
          :style="{
            left: ($el.offsetWidth -8) + 'px',
            width: ($el.offsetWidth + 4) + 'px',
            top: hoveredMenuIndex * 24  + 'px'
          }"
        >
          <div class="submenu-item" v-for="(child, index) of menu.children" :key="index" @click="selectSubMenuItem($event, child);">
            <span style="margin-left: 6px;">{{ (child.index === lastSelectedIndex) ? '&check;&nbsp;' : '&nbsp;&nbsp;&nbsp;&nbsp;'}} {{child.label}}</span>
          </div>
        </div>
      </div>
    
    </div>
  </div>

</template>

<script>

export default {
  name: 'NestedMenu',
  props: {
    menus: Array,
    defaultLabel: String,
    isAwaitingResponse: Boolean,
  },
  created(){
    document.addEventListener('click', () => { this.closeMenuList() });
  },
  mounted() {
    for (let i = 0; i < this.menus.length; i++){
      if (this.menus[i].children.length > 0){
        this.lastSelectedIndex = this.menus[i].children[0].index
        this.displayedListLabel = this.menus[i].children[0].label
        break;
      }
    }
  },
  data() {
    return {
      isMenuListOpen: false,
      hoveredMenuIndex: null,
      displayedListLabel: this.defaultLabel,
      lastSelectedIndex: 0
    }
  },
  emits: [
    'subMenuItemClicked',
  ],
  methods: {
    openMenuList(event) {
      event.stopPropagation();
      if (this.isAwaitingResponse) {
        return;
      }
      this.isMenuListOpen = true;
    },

    closeMenuList() {
      this.isMenuListOpen = false;
      this.hoveredMenuIndex = null;
    },

    setHoveredMenuIndex(index) {
      if (!!index && this.menus[index].children.length === 0){
        this.hoveredMenuIndex = null;
      } else {
        this.hoveredMenuIndex = index;
      }
    },

    selectSubMenuItem(event, child){
      event.stopPropagation();
      this.$emit('subMenuItemClicked', child.index);
      this.lastSelectedIndex = child.index;
      this.displayedListLabel = child.label;
      this.closeMenuList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-list-button {
  text-align: start;
  position: relative;
}

.menu-list {
  width: 100%;
  position: absolute;
  top: 35px;
  left:0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  box-shadow: 1px 3px 3px rgba(100, 100, 100, 0.25);
  border: solid grey 1px; 
}

.submenu-list {
  box-shadow: 1px 3px 3px rgba(100, 100, 100, 0.25);
  border: solid grey 1px; 
}

.menu-item {
  width: 100%;
  height: 20px;
  text-align: start;
  background-color: white;
  height: 24px;
}

.menu-item:hover {
  background-color: #eef8ff;
}

.submenu-item {
  color: black;
  width: 100%;
  text-align: start;
  background-color: white;
  height: 24px;
}

.submenu-item:hover {
  background-color: #eef8ff;
  color: black;
}


</style>
