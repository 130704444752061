<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/html-indent -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  
  <div class="fill-height wrapper d-flex justify-content-center align-self-center login-panel">
    <div class="d-flex flex-column justify-content-center align-items-center login-panel">
      <div>
        <img src="../../assets/HOLCIM_Logo_SmartFlow_lg.png" width="240"/>
      </div>
      <div style="margin-top: 40px;">
        <GoogleLogin :callback="callback" />

      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue"
import { googleOneTap, decodeCredential } from "vue3-google-login" //, CallbackTypes, googleAuthCodeLogin, googleTokenLogin } from "vue3-google-login"
import { useRouter } from 'vue-router'
import router from "../../router/router";

export default defineComponent({
  name: 'LoginScreen',
  data: () => ({
    msg: '',
    isSignIn: false,
  }),
  beforeMount() {
    const storage = useStorage();

    const date = new Date();
    const timestampInMs = date.getTime();
    // get current date time in UNIX
    const unixTimestamp = Math.floor(timestampInMs / 1000);
    // get expire date from google token
    const google_exp_date = storage.getStorageSync("exp_date");

    if (unixTimestamp < google_exp_date) {
      router.push('/PumpingCalculator');
      this.isSignIn = true;
    } else console.log("not connected");
  },
  setups() {
    const router = useRouter();

    onMounted(() => {
      if (this.isSignIn == false)
        googleOneTap({ autoLogin: true })
          .then((response) => {
            // This promise is resolved when user selects an account from the the One Tap prompt
            console.log("Handle the response on googleOneTap", response)
            if (response.hd === "holcim.com" && response.email_verified === true) {
              console.log("Handle the userData Good", response)
              // localStorage.setItem("is_logged", true);
              this.$storage.setStorageSync("is_logged", true);
              return router.push('/PumpingCalculator');
            } else {
              this.msg = "No valid email please use @holcim.com";
              console.log(this.msg);
              console.log("Bad data", response);
            }
          })
          .catch((error) => {
            console.log("Handdle the error", error)
          })
    });
  },
  methods: {
    async handleSubmit() { googleOneTap({ autoLogin: true }) },

    callback: (response) => {
      // decodeCredential will retrive the JWT payload from the credential
      const userData = decodeCredential(response.credential)
      // if (userData.hd === "holcim.com" && userData.email_verified === true) {
      console.log("Handle the userData Good", userData)
      //   router.push('/Home');
      //   return router.push('/PumpingCalculator');
      // } else {
      //   console.log("Bad data", userData)
      // }
    }
  }
})
</script>

<script setup>
import { ref } from 'vue'
import { useStorage } from "vue3-storage";

const count = ref('');
const storage = useStorage();

// const format_date= (value: any) => {
//   if (value) {
//   // return moment(String(date)).format('YYYYMMDD')
//     return moment.unix(value).format("MM/DD/YYYY");
//    }
//   return format_date;
// }

const callback = (response) => {
  const userData = decodeCredential(response.credential)

  if (
    ["holcim.com", "lafargeholcim.com", "lafarge.com", "aggregate.com", "londonconcrete.com", "innocon.on.ca"].includes(userData.hd) && userData.email_verified === true) {
    console.log("Handle the userData Good", userData)
    storage.setStorageSync("userData", userData)
    storage.setStorageSync("exp_date", userData.exp);
    return router.push('/PumpingCalculator');
  } else {
    console.log("Bad data", userData);
    setTimeout(() => { count.value = ''; }, 8000);
    return count.value = 'No valid email please login using @holcim account'
  }
}
</script>

<style scoped>
@import "./LoginScreen.css";
</style>