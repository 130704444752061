// plotlyjs graph layout
export const layout = {
  title: {
    text: 'Concrete Pressure Simulation <br>',
    font: { size: 22, color: '#000' },
    x: 0.2
  },
  xaxis: {
    title: {
      text: 'Flow rate (m³/h)',
      font: { size: 18, color: '#7f7f7f' },
    },
    range: [0,0],
    rangemode: "tozero",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    // white ticks on a white background, allow visual offset of values from axis
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 1,
    // dtick: 5,
  },
  yaxis: {
    title: {
      text: 'Concrete pressure (bars)',
      font: { size: 18, color: '#7f7f7f' },
    },
    rangemode: "nonnegative",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    // white ticks on a white background, allow visual offset of values from axis
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  margin: {
    autoexpand: true,
    l: 0, r: 0, t: 50, b: 200
  },
  legend: { 
    x: -0.2, y: -0.15,
    orientation: "h",
  }
};

// basic settings for pump trace
export const pumpPlot = {
  x: [0], y: [0],
  name: 'Loading...',
  mode: 'lines',
  line: { color: '#e30001', width: 4 },
  hoverlabel: { namelength: -1 },
  legendgroup: 'pump',
};

// basic settings for concrete traces
export const concretePlots = [
  {
    x: [0], y: [0],
    name: 'Loading...',
    mode: 'lines',
    line: { color: 'rgb(0, 0, 255)', width: 3 },
    hoverlabel: { namelength: -1 },
    showlegend: false,
  },
];

// basic settings for pump unsafe pressure area
export const areaPlot = {
  x: [],
  y: [],
  name: 'Unsafe zone',
  mode: 'lines',
  fill: 'toself',
  line: { width: 0 },
  marker: { size: 0, color: '#FF000099' },
  opacity: 0.15,
  showlegend: true,
  legendgroup: 'pump',
};

// plotlyjs graph layout
export const tribologyLayout = {
  title: {
    text: 'Tribology curve',
    font: { size: 22, color: '#000' },
    x: 0.2
  },
  xaxis: {
    title: {
      text: 'velocity (m/s)',
      font: { size: 18, color: '#7f7f7f' },
    },
    range: [0, 1.2],
    rangemode: "tozero",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    // white ticks on a white background, allow visual offset of values from axis
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  yaxis: {
    title: {
      text: 'Interface shear stress (Pa)',
      font: { size: 18, color: '#7f7f7f' },
    },
    rangemode: "tozero",
    showgrid: false,
    autotick: true,
    ticks: 'outside',
    // white ticks on a white background, allow visual offset of values from axis
    tickcolor: '#FFF',
    tick0: 0,
    ticklen: 2,
    // dtick: 5,
  },
  margin: {
    autoexpand: true,
    //l: 0, r: 100, t: 50, b: 200
    l: 0, r: 0, t: 50, b: 200
  },
  legend: { 
    x: -0.2, y: -0.27,
    orientation: "v",
  }
};