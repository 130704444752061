<template>
  <div style="border:20px solid white">
    <br>
    <h5 style="textDecoration:underline">
    About the tool
    </h5>
    <h5>
    Tool developed at Holcim Innovation Center
    </h5>
    <br>
    <br>
    <h5 style="textDecoration:underline">
    Contact<br>
    </h5>
    <h5>
    R&D Project Manager: Paul O'HANLON<br>
    paul.ohanlon@holcim.com
    </h5>
  </div>
</template>

<script>
export default {
  name: "AboutScreen",
};
</script>