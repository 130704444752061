export const defaultConcretes = [
  {
    ID: 'Alstom slump 115mm',
    Velocity: [1.07, 0.93, 0.80, 0.67, 0.53, 0.40, 0.27],
    ShearStress: [596, 554, 524, 473, 419, 347, 295],
    InterfaceViscosity: null,
    YieldStress: null,
    r2: 0.99,
    DensityNoAir: 2.35,
    concreteUUID: "default1",
    linear: {},
    nonLinear:{},
  },
  {
    ID: 'Alstom slump 50mm',
    Velocity: [1.07, 0.93, 0.80, 0.67, 0.53, 0.40, 0.27],
    ShearStress: [669, 634, 597, 537, 490, 418, 379],
    InterfaceViscosity: null,
    YieldStress: null,
    r2: 0.99,
    DensityNoAir: 2.35,
    concreteUUID: "default2",
    linear: {},
    nonLinear:{},
  },
  {
    ID: 'Low Carbon (viscous)',
    Velocity: [1.07, 0.93, 0.80, 0.67, 0.53, 0.40, 0.27],
    ShearStress: [1181, 1043, 896, 743, 579, 433, 314],
    InterfaceViscosity: null,
    YieldStress: null,
    r2: 0.99,
    DensityNoAir: 2.35,
    concreteUUID: "default3",
    linear: {},
    nonLinear:{},
  },
  {
    ID: 'C25 180mm slump',
    Velocity: [1.07, 0.93, 0.80, 0.67, 0.53, 0.40, 0.27],
    ShearStress: [386, 232, 279, 248, 222, 191, 157],
    InterfaceViscosity: null,
    YieldStress: null,
    r2: 0.99,
    DensityNoAir: 2.35,
    concreteUUID: "default4",
    linear: {},
    nonLinear:{},
  },
];