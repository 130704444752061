<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div id="app">
    <nav v-if="!$route.meta.hideNavbar" class="navbar navbar-expand navbar-dark bs-primary-rgb: 15,160,220">
      <router-link to="/" class="navbar-brand">
        <!--
          &nbsp;&nbsp;
          <img src="../src/assets/HOLCIM_Logo_iCONCrete_sRGB_White.png" width="200" />
        -->
      </router-link>
      <div class="navbar-nav ms-auto">
        <!-- Quoted navbar elements, kept for possible future use --><!--
        <li class="nav-item">
          <router-link to="/Home" class="nav-link">
            Home
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/PumpingCalculator" class="nav-link">
            SmartFlow
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/about" class="nav-link">
            About
          </router-link>
        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-primary" @click="logoutFunction">
            logOut
          </button>
        </li>
        -->
      </div>
    </nav>

    <!-- <div class="container mt-3"> -->
    <router-view />
    <!-- </div> -->
  </div>
  
</template>

<script>
import { defineComponent } from "vue";
import { googleLogout } from "vue3-google-login";
import { useStorage } from "vue3-storage";
import router from "./router/router";

export default defineComponent({
  name: "App",
  methods: {
    logoutFunction: () => {
      const storage = useStorage();
      storage.removeStorageSync("exp_date");
      storage.removeStorageSync("is_logged");
      storage.clearStorageSync();
      googleLogout()
      router.push('/');
    }
  }
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Math&display=swap');
.scientific {
  font-family: "Noto Sans Math", sans-serif;
  font-weight: 700;
  font-style: italic;
}

#app {
  background-color: #F7F8FC;
}

.text-white {
  color: white;
}

.light-blue {
  background-color: rgb(4, 187, 241) !important;
}

.dark-blue {
  background-color: rgb(29, 67, 112) !important;
}

nav {
  margin-right: 0.75rem;
}

.form-control {
  border-radius: 2px!important;
  border: 1px solid #0A1828!important;
}

.input-line {
  border: 1px solid #E6E4E3;
  padding: 6px;
}

.input-group-append > .input-group-text{
  border-radius: 0 2px 2px 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  border: 1px #444445 solid;
  height: 38px;
}

.input-hint-danger {
  font-style: normal;
  color: red;
}

.button-no-decoration {
  color: #1A4370;
  padding: 0;
  border: none;
  background: none;
}

.skeleton {
  box-sizing: border-box;
  border: 1px solid red;
}
</style>